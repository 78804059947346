import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import './css/nav.css'

const langs = [
    { code: "en", lang: "/assets/icons/en_flag.jpg" },
    { code: "fr", lang: "/assets/icons/fr_flag.webp" },
    { code: "es", lang: "/assets/icons/es_flag.webp" }
]

const Nav = ({ isIntersecting }) => {
    const { i18n, t } = useTranslation();
    const [menuMobile, setMenuMobile] = useState(false)
    const [toggleSelector, setToggleSelector] = useState(false)

    const normalizeLang = (lang) => lang.split('-')[0];

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang)
        setToggleSelector(false)
    }
    return (
        <header className={isIntersecting ? "fixed" : "sticky"}>
            <nav>
                <Link to="/" className='link'><img src={'../assets/logo.png'} id='logo' alt='logo' /></Link>
                <div className={`menu ${menuMobile ? "menu-active" : ""}`}>
                    <Link to="/apartments" className='link'
                        onClick={() => setMenuMobile(false)}>{t('nav.accommodation')}</Link>
                    {/*<Link to="/" className='link'
              onClick={() => setMenuMobile(false)}>{t('nav.contact')}</Link>
            <Link to="/" className='link'>{t('nav.profil')}</Link>*/}
                    <div className='langage-selector' onClick={() => setToggleSelector(!toggleSelector)}>
                        <img className='flag'
                            src={
                                normalizeLang(i18n.language) === langs[0].code ? langs[0].lang :
                                    (normalizeLang(i18n.language) === langs[1].code ? langs[1].lang : langs[2].lang)
                            } alt='language' />
                        <div className={`selector ${toggleSelector ? "selector-active" : ""}`}>
                            <ul>
                                <li onClick={() => changeLanguage("fr")}>Français</li>
                                <li onClick={() => changeLanguage("en")}>English</li>
                                <li onClick={() => changeLanguage("es")}>Espanol</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='mobile-btn' onClick={() => setMenuMobile(!menuMobile)}>
                    <div className={`bar1 ${menuMobile ? "rotated1" : ""}`}></div>
                    <div className={`middle-bar ${menuMobile ? "vanish" : ""}`}></div>
                    <div className={`bar2 ${menuMobile ? "rotated2" : ""}`}></div>
                </div>
            </nav>
            <div className={`mobile-menu ${menuMobile ? "" : "mobile-menu-close"}`}></div>
        </header>
    )
}

export default Nav;