import React from 'react'
import { Outlet } from 'react-router-dom'
import Nav from '../public/Nav';
import Footer from '../public/Footer';

const PublicInterface = ({ isIntersecting }) => {
  return (
    <>
      <Nav isIntersecting={isIntersecting} />
      <Outlet />
      <Footer />
    </>
  )
}

export default PublicInterface