import { Routes, Route, Navigate, useLocation} from 'react-router-dom';
import PublicInterface from './PublicInterface'
import Home from '../public/pages/Home'
import Apartments from '../public/pages/Apartments'
import Rooms from '../public/pages/Rooms';
import React, { useEffect, useState } from 'react'

function PublicRouter() {
  const [isIntersecting, setIsIntersecting] = useState(false)
  const location = useLocation()

  useEffect(() => {
    setIsIntersecting(false)
    window.scrollTo(0, 0);
  }, [location, setIsIntersecting])

  return (
    <>  
        <Routes>
          <Route element={<PublicInterface isIntersecting={isIntersecting} />}>
          <Route path="/" exact element={<Home onIntersectionChange={setIsIntersecting} />} />
          <Route path="/apartments" exact element={<Apartments />} />
          <Route path="/apartments/:slug" exact element={<Rooms />} />
          <Route path="*" element={<Navigate to='/'/>}/>   
          </Route>
        </Routes>
    </>
  );
}

export default PublicRouter;