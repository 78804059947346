import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import PublicRouter from './routers/PublicRouter'
//import AdminRouter from './routers/AdminRouter'
import './index.css';

export default function Routers () {
    return(
            <Router>
                <Routes>
                    <Route path="/*" element={<PublicRouter />}/>
                </Routes>
            </Router>
    )
}