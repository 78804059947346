import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector/cjs';
import HTTPApi from "i18next-http-backend"

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HTTPApi)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    initImmediate: false,
  });

  i18n.on('initialized', () => {
    const detectedLanguage = i18n.language || i18n.options.fallbackLng;
    document.documentElement.lang = detectedLanguage;
  });

  i18n.on('languageChanged', function(lng) {
    document.documentElement.lang = lng; 
  });

export default i18n;